import React from 'react'

import Image from '../Components/Image'
import Article from '../Components/Article'

function Contact () {
    return(
        <div>
            <Article 
                title='联系方式'
                body={[
                    "联系电话：+86-21-62741199",
                    "电子邮件：gtfire@126.com",
                    "地址：上海市静安区石门二路219号上海丽都新贵9楼H座"
                ]}
                larger= {[0,1,2]} 
            />
            <Image img={{src:"/src/map/map.png"}} />
        </div>
    )
}

export default Contact