import React from 'react'

import Image from '../Components/Image'
import SlideShow from '../Components/SlideShow'

import imageContent from '../Data/care/imageContent'
import slideShowImages from '../Data/careSlideshow/images'

// The page for care
function Care() {
    const displayedImages = imageContent.map(image => (
        <Image img={image} />
    ))
    return(
        <div>
            <SlideShow images={slideShowImages} />
            {displayedImages}
        </div>
    )
}

export default Care