const PATH = '/src/careSlideshow'

//Images for the slideshow
const images = [
    {
        src: `${PATH}/slideshow-1.jpg`,
        id: 0
    },
    {
        src: `${PATH}/slideshow-2.jpg`,
        id: 1
    },
    {
        src: `${PATH}/slideshow-3.jpg`,
        id: 2
    },
    {
        src: `${PATH}/slideshow-4.jpg`,
        id: 3
    }
]

export default images