import React from 'react'

// Footer that always displays on the bottom of the site
function Footer () {
    return(
        <footer>
            <div className="contact-container">
                <a href="tel:+86-21-62741199">
                    <p className="footer-tag">
                        <i className="fa">&#xf095;</i>  +86-21-62741199
                    </p>
                </a>

                <a href="mailto:gtfire@126.com" >
                    <p className="footer-tag">
                        <i className="fa">&#xf0e0;</i>  gtfire@126.com
                    </p>
                </a>

                <a href="https://www.amap.com/search?id=B00155N7GV&city=310106&geoobj=121.467633%7C31.232685%7C121.479528%7C31.238526&query_type=IDQ&query=%E4%B8%BD%E9%83%BD%E6%96%B0%E8%B4%B5&zoom=17.5">
                    <p className="footer-tag">
                        <i className="fa">&#xf279;</i>  上海市静安区石门二路219号上海丽都新贵9楼H座
                    </p>                    
                </a>

            </div>

        </footer>
    )
}

export default Footer