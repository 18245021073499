import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Header from './Components/Header'
import Footer from './Components/Footer'

import Home from './Pages/Home'
import Care from './Pages/Care'
import Qualification from './Pages/Qualification'
import Constructions from './Pages/Constructions'
import ConstructionDetail from './Pages/ConstructionDetail'
import Iot from './Pages/Iot'
import Contact from './Pages/Contact'

// Displays the header and footer and routes the site to display the appropriate pages
function App() {
  return (
    <div className="App">
      <Header />

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        
        <Route path="/care">
          <Care />
        </Route>

        <Route exact path="/constructions">
          <Constructions />
        </Route>

        <Route path="/constructions/:construction" >
          <ConstructionDetail />
        </Route>

        <Route path="/iot">
          <Iot />
        </Route>
      
        <Route path="/contact">
          <Contact />
        </Route>
        
        <Route path="/qualification">
          <Qualification />
        </Route>
      </Switch>

      <Footer />
    </div>
  );
}

export default App
