const PATH = '/src/homeSlideshow'

//Images for the slideshow
const images = [
    {
        src: `${PATH}/slideshow-1.png`,
        text: '中国博览会会展综合体项目',
        link: '/constructions/guozhan',
        id: 0
    },
    {
        src: `${PATH}/slideshow-2.jpg`,
        text: '周浦万达商业广场',
        link: '/constructions/zhoupu-wanda',
        id: 1
    },
    {
        src: `${PATH}/slideshow-3.jpg`,
        text: '上海浦东嘉里城',
        link: '/constructions/pudong-kerry',
        id: 2
    },
    {
        src: `${PATH}/slideshow-4.jpg`,
        text: '上海新世界商城',
        link: '/constructions/xinshijie',
        id: 3
    },
    {
        src: `${PATH}/slideshow-5.jpg`,
        text: '仲盛世界商城',
        link: '/constructions/zhongsheng',
        id: 4
    }
]

export default images