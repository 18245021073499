import React from 'react'

import Image from '../Components/Image'

// Component for an article body in the site
function Article(props) {
    // Maps each paragraph of the article to its own component
    const articleBody = props.body && props.body.map((paragraph, index) => (
        <p 
            className="body" 
            style={{
                textIndent: props.indent ? "50px": "0px",
                fontSize: props.larger.indexOf(index) !== -1 ? "18px" : "16px"
            }} 
            key={index} 
        >
            {paragraph}
        </p>
    ))

    return(
        <div className="article">
            <h3 className="title">{props.title}</h3>
            {props.image && <Image img={{src: props.image, text: props.title}} noText={true} />}
            { articleBody }
            <hr />
        </div>
    )

}

export default Article