import React from 'react'
import ThumbnailHolder from '../Components/ThumbnailHolder'

// The page to display constructions
function Constructions() {
    return(
        <ThumbnailHolder />
    )
}

export default Constructions