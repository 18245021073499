import React from 'react'

import Image from '../Components/Image'
import imageContent from '../Data/qualification/imageContent'

function Qualification() {
    const images = imageContent.map(image => (
        <div>
            <Image img={image} style={{pointerEvents: 'none'}} />
            <hr />
        </div>

    ))

    return(
        <div>
            {images}
        </div>
    )
}

export default Qualification