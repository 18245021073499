import React from 'react'

import Article from '../Components/Article'

import iotData from '../Data/iot/iotData'

function Iot() {
    const content = iotData.map(part => (
        <Article 
            title={part.title} 
            image={part.img} 
            body={part.text}
            larger={part.larger}
            indent={true}
        />
    ))

    return(
        <div>
            {content}
        </div>
    )
}

export default Iot