const PATH ="/src/constructions"

// Informations about the constructions that have been completed
const constructionInfo = [
    {
        dest: "shanghai-wanda",
        title: "上海万达商业广场－获2008年麒麟奖",
        img: `${PATH}/shanghai-wanda/wanda.png`,
        larger: [0,1,2,3],
        text: [
            "项目概况:建筑面积 335,000 平方米 ",
            "层数:24 三幢办公楼,五个商场,电影城,文娱城, 书城,黄金珠宝城",
            "项目用途:综合性商场 施工内容:消防水喷淋、消火栓系统、消防 报警系统的设备供货、安装、联动调试",
            "工程进度:2006.12.23 完成"
        ]

    },
    {
        dest: "xinshijie",
        title: "上海新世界商城－市“白玉兰”奖",
        img: `${PATH}/xinshijie/xinshijie.jpeg`,
        larger: [0,1,2,3,4],
        text: [
            "项目概况:建筑面积 70,000 平方米",
            "层数:地下 2 层,地上 17 层",
            "项目用途:综合性商办楼",
            "施工内容:消防水喷淋系统、消火栓系统、消防报警系统 的设备供货、安装、联动调试",
            "工程进度:2005.5.1 完成, 维修保养至今"
        ]
    },
    {
        dest: "meridien",
        title: "上海世茂国际广场，上海世茂皇家艾美酒店",
        img: `${PATH}/meridien/meridien.jpg`,
        larger: [0,1,2,3,4],
        text: [
            "项目地址:南京东路 789 号 项目概况:建筑面积 190000 平方米",
            "层数:地下 3 层,地上 60 层",
            "项目用途:高级商办楼、综合性商城、超五星级宾馆",
            "施工内容:消防报警系统和气体灭火系统的设备供货、安装、联动调试",
            "工程进度:2006.10.1 完成"
        ]
    },
    {
        dest: "firedept",
        title: "上海市消防局新大楼(消防指挥中心)－获2008年麒麟奖",
        img: `${PATH}/firedept/firedept.jpg`,
        larger: [0,1,2,3,4],
        text: [
            "项目地址:中山西路 207 号",
            "项目概况:建筑面积 20000 平方米",
            "层数:22 项目用途:办公楼",
            "施工内容:消防水喷淋系统、消火栓系统、气体灭火系统、消防 报警系统、细水雾灭火系统的设备供货、安装、联动 调试。",
            "工程进度:2007 年 3 月 26 日, 维修保养至今"
        ]
    },
    {
        dest: "zhongsheng",
        title: "仲盛世界商城",
        img: `${PATH}/zhongsheng/zhongsheng.jpg`,
        larger: [0,1,2,3],
        text: [
            "项目概况:建筑面积 292912 平方米",
            "层数:8 项目用途:综合商场",
            "施工内容:卷帘门系统工程、消火栓系统、消防报警系统的设备供货、安装、联动调试。", 
            "工程进度:2008 年 11 月 28 日完成, 维修保养至今"            
        ]
    },
    {
        dest: "yibai",
        title: "上海市百一店－获2010年麒麟奖，上海市《申按杯》优质安装工程奖",
        img: `${PATH}/yibai/yibai.jpg`,
        larger: [0,1,2,3],
        text: [
            "项目概况:建筑面积 42328.1 平方米",
            "层数:8 项目用途:综合商场",
            "施工内容:消防喷淋系统、消火栓系统、消防报警系统的设备供货、安装、联动调试。",
            "进度:2008 年完成, 维修保养至今"                    
        ]
    },
    {
        dest: "zhoupu-wanda",
        title: "周浦万达商业广场－获2010年麒麟奖，上海市《申按杯》优质安装工程奖",
        img: `${PATH}/zhoupu-wanda/zhoupu-wanda.png`,
        larger: [0,1,2,3,4],
        text: [
            "项目概况:建筑面积 340，000 平方米", 
            "层数:五大单体,包括商场,电影城,办公楼、 超市等",
            "项目用途:综合性商场",
            "施工内容:消防水喷淋系统、消火栓系统、消防 报警系统、自动智能扫描灭火系统的设备供货、安装、联动调试", 
            "工程进度:2009 年 10 月 30 日完成"                               
        ]
    },
    {
        dest: "pudong-kerry",
        title: "上海浦东嘉里城－获2011年麒麟奖，市“白玉兰”奖",
        img: `${PATH}/pudong-kerry/pudong-kerry.jpeg`,
        larger: [0,1,2,3,4],
        text: [
            "项目概况:建筑面积 340，000 平方米", 
            "层数:五大单体,包括商场,电影城,办公楼、 超市等",
            "项目用途:综合性商场",
            "施工内容:消防水喷淋系统、消火栓系统、消防 报警系统、自动智能扫描灭火系统的设备供货、安装、联动调试", 
            "工程进度:2009 年 10 月 30 日完成"                               
        ]
    },
    {
        dest: "jingan-temple",
        title: "静安寺",
        img: `${PATH}/jingan-temple/jingan-temple.png`,
        larger: [0,1,2,3,4],
        text: [
            "项目地址:南京西路", 
            "项目概况:建筑面积近 15000 平方米", 
            "项目用途:寺庙", 
            "施工内容:消防水喷淋系统、消火栓 系 统、消防报警系统的设备供货、安 装、 联动调试。",
            "工程进度:完成"                              
        ]
    },
    {
        dest: "nongshang118",
        title: "农工商超市118店",
        img: `${PATH}/nongshang118/nongshang118.png`,
        larger: [0,1,2,3],
        text: [
            "项目地址:普陀区金沙江路 1685 号", 
            "项目概况:建筑面积近 15.5 万m² ",
            "项目用途:超市、商场 施工内容:消防水喷淋系统、消火栓、系统、消防报警系统、防 排烟系统、防火卷帘门、 室外管网的设备供货、安装、联动调试", 
            "工程进度:在建"                   
        ]
    },
    {
        dest: "xinjiangwan",
        title: "上海市新江湾城",
        img: `${PATH}/xinjiangwan/xinjiangwan.png`,
        larger: [0,1,2,3],
        text: [
            "项目地址:杨浦区殷高路以南,新江湾城以西",
            "项目概况:建筑面积近 15.6 万平方米",
            "项目用途:高级住宅、会所、地下车库。 施工内容:消防水喷淋系统、消火栓系统、消防报警系统、气体系统的设备供货、安装、联 动调试。此项目做深化设计。",
            "工程进度:2014 年 1 月 22 日完成"                               
        ]
    },
    {
        dest: "bfc",
        title: "外滩国际金融服务中心",
        img: `${PATH}/bfc/bfc.png`,
        larger: [0,1,2,3,4],
        text: [
            "项目地址:上海市黄浦区外滩8-1地块,“地王”板块。项目概况:总用地面积 5.7 公顷,总建筑面积地达 42 万m²。地上建筑面积 27 万m², 地下建筑面积为 15 万m²。 项目用途:办公楼、商业文化中心", 
            "施工内容:消防消防报警系统、高压细水雾系的设备供货、安装、联动调试。",
            "此项目做 BIM 深化设计。", 
            "工程进度:在建"                                       
        ]
    },
    {
        dest: "guozhan",
        title: "中国博览会会展综合体项目",
        img: `${PATH}/guozhan/guozhan.jpg`,
        larger: [0,1,2,3,4],
        text: [
            "项目地址:北至崧泽高架、南至规划盈港东路、西至诸光路、东至规划涞港路的合围之处", 
            "项目概况:建筑面积 147 万m²,地上建筑面积 127 万m², 地下建筑面积 20 万m²,建筑高度 43 米。", 
            "项目用途:展厅、办公楼、会议中心、商业中心", 
            "施工内容:消防喷淋系统、消火栓系统、水喷雾系统、大 空间洒水器系统、自动消防炮灭火系统、消防 贮水设备、消防水泵、灭火器的设备供货、安装、联动调试。此项目做深化设计。",
            "工程进度:在建"                                               
        ]
    },
    {
        dest: "dawan",
        title: "上海新世界名品城",
        img: `${PATH}/dawan/dawan.png`,
        larger: [0,1,2,3,4],
        text: [
            "项目地址:黄浦区南京东路以北、河南中路以东、天津路以南、江西中路以西的合围之处。", 
            "项目概况:建筑面积 118，206 万平方米。", 
            "项目用途:顶级奢侈品百货", 
            "施工内容:消防喷淋系统、消火栓系统、大空间洒水器系统、自动消防炮灭火系统、消防报警系统的设备供货、安装、联动调试。此项目做深化设计。",
            "工程进度:在建"                                                          
        ]
    },
]

export default constructionInfo