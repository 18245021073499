// Image for the qualification page
const imageContent = [
    {
        src: './src/qualification/营业执照副本-2023.jpg'
    },
    {
        src: './src/qualification/资质210420-260419.jpg'
    },
    {
        src: './src/qualification/维保检测资质2020.jpg'
    },
    {
        src: './src/qualification/电气防火检测资质.jpg'
    },
    {
        src: './src/qualification/安全评估资质.jpg'
    },
    {
        src: './src/qualification/安全证23-26.jpg'
    },
    {
        src: './src/qualification/基本账户开户证明.jpg'
    }
]

export default imageContent