const PATH = "/src/iot"

const iotData = [
    {
        title: "消防远程监控系统介绍",
        img: `${PATH}/product-render.png`,
        larger: [0,1,2],
        text: [
            "FS8000消防远程监控系统是我司凭借在消防报警行业多年的技术积累的基础上，根据国内消防行业现状，开发完成的系统，并根据实际需求不断完善相关模块和功能。",
            "该系统采用了有线、无线通讯技术、自动报警控制技术、火警与视频监控联动技术、地理信息技术，可实时接收、显示、处理联网单位的报警系统信息。",
            "同时根据当前消防现实情况，物联网技术和互联网技术的发展，将消防水系统监管，消防设施管理，无线火灾报警，以及远程APP应用于维护集成进系统平台。"    
        ]
    },
    {
        title: "平台介绍：标准规范",
        larger: [0,1,2,3,4,5,6,7,8,9],
        text: [
            "GB50440-2007 《城市消防远程监控系统技术规范》",
            "GB26875-2011《城市消防远程监控系统》",
            "GB16806-2006《消防联动控制系统》",
            "GA767-2008 《消防控制室通用技术要求》",
            "GB/T 50314-2006 《智能建筑设计标准》",
            "GB 50312-2007 《综合部线系统工程验收规范》",
            "GB 50339-2003 《智能建筑工程质量验收规范》",
            "GB 50116  《火灾自动报警系统设计规范》",
            "GA 587  《建筑消防设施的维护管理》",
            "GB 50313-2000 《消防通信指挥系统设计规范》"             
        ]
    },
    {
        title: "平台介绍：GIS地图应用",
        larger: [],
        img: `${PATH}/gis-map.png`
    },
    {
        title: "平台介绍：视频联动",
        larger: [],
        img: `${PATH}/video-interaction.png`
    },
    {
        title: "平台介绍：消防水系统监管",
        larger: [],
        img: `${PATH}/liquid-monitor.png`,
    },
    {
        title: "系统功能及特点介绍",
        img: `${PATH}/system-intro.png`,
        larger:[0],
        text: [
            "FS8000消防远程监控系统主要由火灾报警监控监控中心平台、传输网络、传输终端三大部分组成。",
        ]
    },
    {
        title: "监控中心平台",
        larger: [0],
        text: [
            "监控中心平台作为远程监控系统中对各类信息进行集中管理的节点，负责接受和处理联网用户的火灾报警信息和其他相关信息，并经核实确认后提供给城市消防通信指挥中心。",
            "•实时自动接收联网用户的火灾自动报警信息",
            "•多屏同步显示报警联网用户的报警时间、名称、地址、联系电话、地理信息等报警信息",
            "•接收、存储用户信息传输装置发送的建筑消防设施运行状态信息，并可进行跟踪、记录、查询和统计",
            "•显示、查询报警信息的历史记录和相关信息",
            "•具有系统自检及故障报警功能"
        ]
    },
    {
        title: "传输网络",
        larger: [0],
        text: [
            "系统传输主要依托电信本地公众网络提供的固定网（有线）和移动网（无线）接入平台，以及宽带运营商提供的互联网平台，目前支持的传输方式包括：",
            "•Internet",
            "•虚拟专用网络",
            "•GPRS、CDMA公用移动通信网",
            "•PSTN公用电话网"      
        ]
    },
    {
        title: "火灾报警监控传输终端",
        img: `${PATH}/product-render.png`,
        larger: [0],
        text: [
            "与联网消防重点单位的火灾自动报警系统控制器等建筑消防设施相连接线，直接获取其探测器信息、系统状态信息、操作信息和故障信息，并按统一标准协议对外传输的装置。"   
        ]
    },
    {
        title: "系统方案特点",
        img: `${PATH}/system-structure.png`,
        larger: [0,1],
        text: [
            "FS8000型消防远程监控系统，是在火灾自动报警系统基础上建立的提高现代化管理水平和火灾报警快速反应能力的重要手段。",
            "它采用当代最新的GPRS网络技术、GIS地理信息技术、MIS消防安全管理系统、DBMS数据库技术、宽带网络技术和自动化控制技术，是将计算机技术和现代化网络通信技术相结合的科技含量较高的软、硬件综合型产品。",
            "•统一性：系统的所有软硬件统一考虑，即成为一个整体。对整个系统的维护与管理本方案将做成一个统一的组件来完成，包括用户及角色的管理、统一权限管理、系统数据的维护与管理等功能，方便用户使用和管理。",
            "•合理性：采用集中接警、分类处警，并能够实现一级和多级分布式处警模式，适用有较完备的通信网络和计算机网络的城市。",
            "•实用性：从用户的角度出发，通过对纷繁复杂的业务流程和用户需求进行详细分析最后收敛成多个专业子系统，并以简单、快速、高效、准确、可靠为原则，实现系统既定的目标和任务。",
            "•先进性：依据最新的国家标准和可能在未来实时的标准，在信息技术不断发最的今天，在系统建成以后槲当长的一段时间内不落后而且能持续满足用户需求的增长。",
            "•安全可靠性：消防远程监控系统是一个对安全、稳定、可靠运行要求很高的系统，本方案在核心系统、关键设备上大量采用了双机热备份手段以及关键部件的冗余改计。从网络到应用到数据采用多级可靠性保护措施，系统具有容错性和抗灾难性，保证系统全年365x 24小时不间断运行。",
            "•可扩展性：系统软件采用模块化设计，具有充分扩展性，满足可持续发展性，延长系统生命周期",
            "•开放性：基于开放的软硬什平台，采用标准的、可扩展的技术，遵循开放系统的国际标准。应用软件系统相对独立于系统软件平台和硬件平台，可移植性、伸缩性、通用性都比较好，系统具有良好的与外部系统的互连互通能力。",
            "•规范性：整个系统从设计到建设整个过程严格按照国家系统集成和软件工程规范和程序操作。"
        ]
    }
]

export default iotData