// Image for the care page
const imageContent = [
    {
        src: './src/care/care1.jpg'
    },
    {
        src: './src/care/care2.jpg'
    }
]

export default imageContent