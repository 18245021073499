import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import constructionInfo from '../Data/constructions/constructionInfo'

import Article from '../Components/Article'

// The construction detail information that user will see after clicking the thumbnail
function ConstructionDetail() {
    const { params} = useRouteMatch()

    // Find the particular construction
    const target = constructionInfo.filter(obj => obj.dest === params.construction)
    if (target.length === 0) {
        // The construction is not found in this case
        return(
            <h1>
                The construction do not exist... Please check your url. 
            </h1>
        )
    }
    const construction = target[0]
    return(
        <Article 
            title={construction.title} 
            body={construction.text} 
            image={`${construction.img}`}
            larger={construction.larger}    
        /> 
    )
}

export default ConstructionDetail

