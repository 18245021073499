import React from 'react'

import SlideShow from '../Components/SlideShow'
import Article from '../Components/Article'

import introText from '../Data/Introduction/text'
import images from '../Data/homeSlideshow/images'

// The home page of the site
function Home() {
    return (
        <div>
            <SlideShow 
                images = {images} 
            />
            <Article
                title ='公司简介'
                body = {introText}
                indent = {true}
                larger = {[0,1,2]}
            />
        </div>
    )
}

export default Home