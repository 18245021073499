import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import Image from './Image'

// The Slideshow displayed on the home page
function SlideShow (props) {
    // The index of the photo being displayed
    const [ slideIndex, setSlideIndex ] = useState(0)

    // Modify the displayed index
    let plusDivs = (n) => {
        setSlideIndex((prevSlideIndex) => {
            let index = prevSlideIndex + n
            if (index > props.images.length - 1) {
                return 0
            } else if (index < 0) {
                return props.images.length - 1
            }
            return index
        })
        console.log(slideIndex)      
    }

    // Automatically move to the next photo every 9 seconds
    useEffect( () => {
        let timerId = setInterval(() => plusDivs(1), 9000)
        return () => {
            console.log('unmounting')
            clearInterval(timerId)
        }
    }, []
    )

    // The images to be displayed
    let slides = props.images.map(image => (
        <Link to={image.link}>
            <Image 
                className="mySlides w3-animate-fading" 
                img={image} 
                style={{display: image.id === slideIndex ? "block" : "none"}}
            />
        </Link>

    ))

    return(
        <div className="image-display w3-content w3-display-container">
            {slides}

            <button 
                id="image-slide-left" 
                className="w3-button w3-black w3-display-left"
                onClick={() => plusDivs(-1)}
            >
                &#10094;
            </button>
            <button 
                id="image-slide-right" 
                className="w3-button w3-black w3-display-right"
                onClick={() => plusDivs(1)}
            >
                &#10095;
            </button>
        </div>
    )
}

export default SlideShow