import React from 'react'
import { Link } from 'react-router-dom'

// The header that is always displayed to help navigate the site
function Header () {
    return(
        <header>
            <div className="header">
                <Link to="/" ><img className="logo" src={'/src/logo.png'} alt="logo" /></Link>
                <nav className="header-right">
                    <Link className="active text" to="/">网站首页</Link>
                    <Link className="text" to="/care">领导关怀</Link>
                    <Link className="text" to="/qualification">荣誉资质</Link>
                    <Link className="text" to="/constructions">工程案例</Link>
                    <Link className="text" to="/iot">消防物联网</Link>
                    <Link className="text" to="/contact">联系我们</Link>
                </nav>
            </div>
        </header>
    )
}

export default Header