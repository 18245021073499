import React from 'react'

// Display an image with the option of caption
function Image(props) {
    return(
        <div style={props.style} className={`${props.className} image-container`}>
            <img src={props.img.src} alt={props.img.text} className="image" />
            {(props.img.text && !props.noText) && 
                <div class="w3-display-bottomleft w3-medium w3-container w3-padding-8 w3-black">
                    {props.img.text}
                </div>}
        </div>
    )
}

export default Image