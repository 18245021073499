import React from 'react'

import ConstructionThumbnail from '../Components/ConstructionThumbnail'

import constructionInfo from '../Data/constructions/constructionInfo'

// Displays an array of thumbnails in a page
function ThumbnailHolder () {
    const thumbnails = constructionInfo.map(construction => <ConstructionThumbnail src={construction} />)

    return(
        <div className="construction-thumbnails-holder">
            {thumbnails}
        </div>
    )
}

export default ThumbnailHolder