import React from 'react'
import { Link } from 'react-router-dom'

// Thumbnail displaying the photo of the construction and a title
function ConstructionThumbnail(props) {
    return(
        <div className="construction-thumbnail">
            <Link to={`/constructions/${props.src.dest}`} >
                <img src={props.src.img} alt={props.src.title} />
                <div className="title-caption"><p>{props.src.title}</p></div>
            </Link>
        </div>
    )
}

export default ConstructionThumbnail